.order-sucess {
  display: -webkit-inline-box;
  display: inline-flex;
  line-height: 1.5em;
  color: #000000;
  background: #6be87b;
  margin: -0.25em 0;
  cursor: inherit !important;
  white-space: nowrap;
  max-width: 100%;
  border: 1px solid green;
  border-radius: 5px;
  padding: 2px 4px;
}
.order-not-complete {
  display: -webkit-inline-box;
  display: inline-flex;
  line-height: 1.5em;
  color: #000000;
  background: #d2e0d4;
  margin: -0.25em 0;
  cursor: inherit !important;
  white-space: nowrap;
  max-width: 100%;
  border: 1px solid rgb(163, 182, 163);
  border-radius: 5px;
  padding: 2px 4px;
}

.viewPolyScanButton {
  border: 1px solid #ccc;
  width: 100%;
  background-color: #7366ff;
  color: white;
  font-weight: 400;
}

.columnStyles{
  word-break: break-all;
}