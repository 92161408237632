.collectionModalWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 650px;
    font-family: Rubik, sans-serif;
    background-color: white;
    margin-top: 7rem;
}

.collectionTableWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 450px !important;
    overflow: scroll;
    /* padding-top: 22rem; */
}

.collectionModalHeading {
    flex: 0 0 93%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.collectionModalCloseButton {
    flex: 0 0 7%;
}