.fileUploadWrapper {
  width: 50%;
  height: 700px;
  border-radius: 8px;
  border: 1px solid #cec8c8;
  background-color: #eaeaea;
}

.flexRowNowrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.flexColumnNowrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.flexRowCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
}

.fileUploadPreviewSideMenu {
  flex: 0 0 20%;
}

.fileUploadPreviewContent {
  display: flex;
  flex-direction: column;
  flex: 0 0 80%;
  margin: 12px 0;
  background-color: #ccc;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.fileUploadPreview {
  padding: 10px 60px;
}

.fileUploadPreviewWrapper {
  border: 1px dashed #ccc;
  padding: 32px;
  border-radius: 20px;
}

.fileUploadPreviewBox {
  height: 50px;
  width: 50px;
  background-color: #ccc;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 2px;
  margin: 12px;
}

.fileUploadPreviewContent-header {
  text-align: right;
  padding: rig;
  padding-right: 28px;
  font-weight: 600;
  flex: 0 0 10%;
}

.fileUploadPreviewContent-content {
  flex: 0 0 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fileUploadPreviewContent-footer {
  flex: 0 0 10%;
  text-align: center;
  font-weight: 600;
}

.fileUploadAction {
  padding: 10px 60px;
}

.fileUploadActonMainFiles {
  flex: 0 0 20%;
}

.fileUploadActonSupportingFiles {
  flex: 0 0 80%;
}

.fileUploadActionMainItems {
  width: 100px;
  height: 114px;
  border: 1px solid #f8f8f8;
  border-radius: 10px;
  background-color: #fff;
}

.fileUploadActonSupportingFiles {
  border: 1px solid #f8f8f8;
  border-radius: 10px;
  background-color: #fff;
}

.fileUploadActionBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 66px;
  width: 98px;
  border: 1px dashed #ccc;
  border-radius: 8px;
  padding: 2px;
  margin: 12px;
}

.fileUploadMainActionBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 66px;
  width: 70px;
  border: 1px dashed #ccc;
  border-radius: 8px;
  padding: 2px;
  margin: 12px;
  background-color: #ccc;
}

.fileUploadFooter {
  display: flex;
  justify-content: center;
  margin-top: 14px;
}

.fileUploadLabel {
  font-weight: 500;
}

.fileUploadHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 7px 14px;
}
