@import "assets/scss/app.scss";
@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";

// login/signup styling
.outer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  .form-control {
    &:focus {
      border-color: #167bff;
      box-shadow: none;
    }
  }
  h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
  }
}
.inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
  margin-top: 20vh;
}
.custom-control-label {
  font-weight: 400;
}
.forgot-password {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
  a {
    text-align: right;
    font-size: 13px;
    padding-top: 10px;
    color: #7f7d7d;
    margin: 0;
    color: #167bff;
  }
}

.footer{
  position: fixed;
  width: 100%;
  margin-left:0px !important;
}
.page-wrapper.compact-wrapper .page-body-wrapper .page-body{
  padding-bottom: 100px;
}

.d-flex-aic {
  display: flex;
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.ws-no-wrap {
  white-space: nowrap;
}