.outOfStock {
  color: #fff;
  background-color: #dc3545;
  width: 71px;
  font-size: 12px;
  padding: 0 2px;
}
.fixedLabel {
  color: #fff;
  background-color: green;
  width: 60px;
  font-size: 16px;
  padding: 0 2px;
  text-transform: capitalize;
  text-align: center;
}

.auctionLabel {
  color: #fff;
  background-color: rgb(228 141 50);
  width: 60px;
  font-size: 16px;
  padding: 0 2px;
  text-transform: capitalize;
}

.imgCard {
  height: 120px;
  width: 120px;
  border: 1px solid #eee;
  background-color: #eee;
  display: inline-block;
  margin: 5px;
  text-decoration: none;
  color: black;
  text-align: center;
  font-family: "Lato", sans-serif;
  transition: 250ms all ease-in-out;
}

.imgCard:hover {
  box-shadow: 0 0 10px red;
  transform: scale(1.03);
}

.imgCard:hover .imgCard-text {
  text-decoration: underline;
}

.imgCard > img {
  width: 120px;
  height: 120px;
}

.widthMax29p {
  max-width: 29%;
}

.searchBoxProduct {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  flex: 0 0 50%;
}

.flexColumnCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noResultFound  {
  margin-top: 3rem;
}
.noResultFound > span {
  font-size: 16px;
  font-weight: 600;
  margin-top: 22px;
}

.category-wrapper {
  border: 1px solid #ccc;
  border-radius: 5px;
  display: inline-block;
  padding: 2px 4px;
  background-color: white;
  text-transform: capitalize;
}

.productTableHeaderWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width : 1200px) {
  .productTableHeaderWrapper{
    flex-wrap: wrap-reverse;
    gap:20px
  }
}

.tabSectionProducts {
  /* flex: 0 0 50%; */
}

.MuiPagination-ul {
  margin: 10px 0px !important;
  justify-content: space-between;
  font-size: 17px;
}

.viewOnPolygonScan {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 0px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  background-color: white;
  color: black;
  border: 2px solid #008cba;
  border-radius: 5px;
}

.viewOnPolygonScan:hover {
  background-color: #008cba;
  color: white;
}

.totalAsset {
  background: rgb(232, 244, 253);
  min-width: fit-content;
  padding: 4px 9px;
  border: 1px solid #e8f4fd;
  border-radius: 6px;
  text-align: center;
}

.totalAssetLabel {
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
}

.totalAssetContent {
  font-weight: 500;
}
