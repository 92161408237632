.commissionTableHeader {
  font-weight: 600;
}

.commissionTableContent {
  margin-left: 10px;
}

.paddingLeft78 {
  padding-left: 78px;
}

.width50p {
  width: 50% !important;
}

.commissionSettingWrapper > h5 {
  font-size: 1.15rem;
}

.containers {
  display: flex;
  margin-left: 50px;
  margin-top: 45px;
}

/* .containers .container.cone {
  margin-right: 40px;
} */

.containers .container .icon {
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 9px;
}

.containers .container h4 {
  margin-left: 22px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #232532;
}

.containers .container p {
  width: 121px;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #232532;
  margin-left: 20px;
  margin-top: 30px;
}

.containers .container .btn {
  width: 205px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #5b33de;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #5b33de;
  margin-left: 20px;
  margin-top: 43px;
}

.containers .container {
  width: 533px;
  height: 300px;
  background: #ffffff;
  border: 1px solid #ccd0ee;
  border-radius: 5px;
}

.flexCenter {
  display: flex;
  justify-content: center;
}

.flexRowFlexEnd {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.percentageImgContainer {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 9px;
}

.displayFlexBaseline {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  position: relative;
}

.marginLeft10pxN {
  margin-left: -10px;
}
