.width30vw {
  width: 30vw;
}


.uploadButton{
  width: 100%;
  cursor: pointer;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
}