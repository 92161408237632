.ck-editor__editable_inline {
  min-height: 150px;
}

.ql-editor {
  min-height: 150px;
}

.ql-editor ol {
  display: flex;
  flex-direction: column;
}

.addProductFlexCenter {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.maxWidth56p {
  max-width: 56%;
}

.maxHeight50p {
  max-height: 50%;
}

.addProduct-bg-grey {
  background: #aaa;
}

.height50p {
  height: 50%;
}

.height41p {
  /* height: 41%; */
  height: 400px;
}

.maxHeightWidth100p {
  max-width: 100%;
  max-height: 100%;
}

.cursorPointer {
  cursor: pointer;
}

.colorRed {
  color: red;
}

.charactersRemaining {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  opacity: 0.9;
}

.charactersRemaining > span {
  font-size: 12px;
}

.addProductFlexRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.disableAndHideDiv {
  opacity: 0%;
  pointer-events: none;
}
.separator {
  margin-bottom: 10px;
  padding: 12px 5px;
  background-color: #f4f4f4da;
  border-radius: 5px;
}

.disabledbtn {
  background-color: #786fdb71 !important;
  border: #786fdb71 !important;

}

.disabledbtn:hover {
  background-color: #786fdb71 !important;
  border: #786fdb71 !important;
}

.removePadding {
  padding: 0px !important;
}

.percentageText {
  padding-left: 30px !important;
}

.percent-symbol {
  position: absolute;
  top: 7px;
  left: 10px;
  font-size: 16px;
}
.ethValueDisplayer {
  flex: 0 0 48%;
  flex-shrink: 0;
  padding: 9px 18px;
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px #45b26b;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #45b26b;
  margin-left: 2%;
}
/* 
.usdcValueDisplayer {
    flex: 0 0 48%;
    flex-shrink: 0;
    padding: 9px 18px;
    border-radius: 2px;
    box-shadow: inset 0 0 0 1px #007bff;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: #007bff;
    margin-top: 5px;
} */

.imgGreyBg {
  background-color: #bfbfbf;
}

.paddingLeft14px {
  padding-left: 14px;
}

.videoInPreviewWidth video {
  min-width: 300px;
}

.currencyInput {
  border: 1px inset #ccc;
  display: flex;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  width: 50%;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.currencyInput input {
  border: 0px;
  outline: none;
  width: 100%;
  height: 100% !important;
}

.height240px {
  height: 240px;
}

.marginLeft6px {
  margin-left: 6px;
}
