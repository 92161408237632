.voiletTickImage {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.alignItemStart {
  align-items: flex-start !important;
  justify-content: left !important;
}

.threeDotContainer {
  background: white;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 4px 0px rgb(0 0 0 / 75%);
  width: 116px;
  height: 109px;
  /* padding: 16px 18px; */
  position: absolute;
  z-index: 4;
  left: 216px;
}

.threeDotContainerAlignment {
  left: -112px !important;
  bottom: -49px;
}

@media only screen and (max-width: 1449px) {
  .threeDotContainerAlignment {
    left: 75px !important;
  }
}

@media only screen and (max-width: 1349px) {
  .threeDotContainerAlignment {
    left: 45px !important;
  }
}

@media only screen and (max-width: 1249px) {
  .threeDotContainerAlignment {
    left: 25px !important;
  }
}

.editTableRow,
.blockTableRow {
  align-items: center;
  vertical-align: middle;
  margin-bottom: 17px;
  margin-top: 4px;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-weight: 600;
}

.width100Percentage {
  width: 100% !important;
}

.paddingLeft22px {
  padding-left: 22px;
}

.paddingTop18px {
  padding-top: 18px !important;
}

.cursorPointer {
  cursor: pointer;
}

.iconAvatar {
  height: 29px;
  width: 29px;
  border-radius: 50%;
  background-size: cover;
}

.optionsDotsCss {
  padding: 16px;
}

.userBlockStyle {
  display: inline-flex;
  line-height: 1.5em;
  color: #ffffff;
  background: #df561f;
  margin: -0.25em 0;
  cursor: inherit !important;
  white-space: nowrap;
  max-width: 100%;
  border: 1px solid rgb(163, 182, 163);
  border-radius: 5px;
  padding: 2px 4px;
}
.userActiveStyle {
  display: inline-flex;
  line-height: 1.5em;
  color: #ffffff;
  background: #5ba936;
  margin: -0.25em 0;
  cursor: inherit !important;
  white-space: nowrap;
  max-width: 100%;
  border: 1px solid rgb(163, 182, 163);
  border-radius: 5px;
  padding: 2px 4px;
}

.height55px {
  height: 55px !important;
}

.displayFlex {
  display: flex;
}

.columnStyles {
  word-break: break-all;
}

.TotalUsers {
  background: rgb(232, 244, 253);
  width: 12rem;
  padding: 4px 9px;
  border: 1px solid #e8f4fd;
  border-radius: 6px;
  text-align: center;
}

.totalUserLabel {
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
}

.totalUserContent {
  font-weight: 500;
}
