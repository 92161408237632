.flexColumnWrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.marginTop1p {
  margin-top: 1%;
}

.billing-details-label {
  opacity: 0.7;
}

.order-details-content {
  opacity: 0.7;
}
