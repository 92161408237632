.permission_checkbox_all {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ced4da;
    margin-bottom: 10px;
}
.permission_checkbox_siblings {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
   
}
.permission_checkbox {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
   
}
.permission_checkbox > span {
    margin-right:3px;
}