.fileUploadWrapper {
  width: 100% !important;
  border-radius: 8px;
  border: 1px solid #cec8c8;
  background-color: #eaeaea;
  padding-bottom: 15px;
  height: auto !important;
}

.flexRowNowrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.flexColumnNowrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.imagPOS {
  background-repeat: no-repeat;
  background-position: center;
}
.flexRowCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
}

.fileUploadPreviewSideMenu {
  flex: 0 0 18%;
}
.fileUploadPreveiwSideMenuLabel {
  flex: 0 0 10%;
  max-width: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.fileUploadPreviewContent {
  display: flex;
  flex-direction: column;
  flex: 0 0 72%;
  margin: 12px 0;
  background-color: #ccc;
  border: 1px solid #ccc;
  border-radius: 8px;
  justify-content: center;
  position: relative;
  justify-content: flex-start;
}

.fileUploadPreview {
  padding: 10px 60px;
}

.fileUploadPreviewWrapper {
  border: 1px dashed #ccc;
  padding: 32px;
  border-radius: 20px;
}

.fileUploadPreviewBox {
  height: 50px;
  width: 50px;
  background-color: #ccc;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 2px;
  margin: 12px;
  display: flex;
}

.fileUploadPreviewContent-header {
  text-align: right;
  padding: rig;
  padding-right: 28px;
  font-weight: 600;
  flex: 0 0 10%;
}

.fileUploadPreviewContent-content {
  flex: 0 0 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fileUploadPreviewContent-footer {
  flex: 0 0 10%;
  text-align: center;
  font-weight: 600;
}

.fileUploadAction {
  padding: 10px 60px;
}

.fileUploadActonMainFiles {
  flex: 0 0 20%;
}

.fileUploadActonSupportingFiles {
  flex: 0 0 80%;
}

.fileUploadActionMainItems {
  width: 100px;
  height: 114px;
  border: 1px solid #f8f8f8;
  border-radius: 10px;
  background-color: #fff;
}

.fileUploadActonSupportingFiles {
  border: 1px solid #f8f8f8;
  border-radius: 10px;
  background-color: #fff;
}

.fileUploadActionBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 66px;
  width: 98px;
  border: 1px dashed #ccc;
  border-radius: 8px;
  padding: 2px;
  margin: 12px;
}

.fileUploadMainActionBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 66px;
  width: 70px;
  border: 1px dashed #ccc;
  border-radius: 8px;
  padding: 2px;
  margin: 12px;
  background-color: #ccc;
  position: relative;
}

.fileUploadFooter {
  display: flex;
  justify-content: center;
  margin-top: 14px;
}

.fileUploadLabel {
  font-weight: 500;
  padding-left: 12px;
}

.fileUploadHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 7px 14px;
}

.fileUploadPreviewContent-content > img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.fileUploadPreviewContent-content {
  max-height: 284px;
  max-width: 421px;
}
.displayFlex {
  display: flex;
}
.textWidth98 {
  padding-left: 12px;
  width: 112px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}
.opacity3 {
  opacity: 0.3;
  cursor: default;
}
.cursorDefault {
  cursor: default !important;
}
.previewImg {
  height: 200px;
  width: 100%;
  object-fit: contain;
}
.defaultThumbnailCss {
  object-fit: contain;
}
.alignSelfCenter {
  align-self: center;
}

.marginLeft78px {
  margin-left: 78px;
}

.marginLeft44px {
  margin-left: 44px;
}

.displayNone {
  display: none;
}

.fileUploadMainFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.removeUploaded {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  top: -6px;
  left: 8px;
}

.closeButtonRemoveUpload {
  background: red;
  color: white;
  font-weight: 700;
  border-radius: 13px;
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fileUploadTool {
  padding: 10px 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.acceptedFileFormat {
  flex: 0 0 86%;
}

.fileUploadDeleteAllButtonWrapper {
  flex: 0 0 14%;
}

.fileUploadDeleteAllButton {
  color: #fff;
  background-color: #dc3545;
  border: 1px solid #dc3545;
  border-radius: 4px;
  font-weight: 500;
}

.marginLeft25px {
  margin-left: 25px !important;
}
