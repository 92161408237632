.marginLeft20 {
  margin-left: 20px;
}

.cursorPointer {
  cursor: pointer;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgb(0 0 0 / 13%);
  color: #666;
  display: flex !important;
  flex-direction: column !important;
  /* justify-content: center; */
  align-items: center !important;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #4433ff !important;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

.root {
  margin-top: 0px;
  border: none;
  width: 100%;
};
.header {
  background-color: #F5F5FC;
};
.rowDatas  td,th {
    border: none;
    height: 90px;
  };
.border__right {
  border-right: "2px solid white";
  height: "70%";
};
.cursorDefault {
  cursor: "default";
}