.logoWrapperLogin {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.loginCenter {
  text-align: center;
}
.logoImageLogin {
  width: 44px;
  height: 55px;
  margin-bottom: 15px;
}

.versionNumber {
  margin-top: 14px;
  text-align: center;
}

.passwordEyeIcon {
  position: absolute;
  left: 91%;
  top: 16%;
  cursor: pointer;
}

.positionRelative {
  position: relative;
}
