.messageBoxStyle {
  width: 66px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  justify-content: center;
}

.captitalize {
  text-transform: capitalize;
}
.columnStyles-sm,
.columnStyles {
  word-break: break-all;
}

.CommissionBlueBox {
  background: rgb(232, 244, 253);
  width: 8rem;
  padding: 4px 9px;
  border: 1px solid #e8f4fd;
  border-radius: 6px;
}

.boxLabel {
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
}

.boxContent {
  font-weight: 500;
}
