.boleroTabsSection {
  display: flex;
}

.UsersTabOption {
  background: #e9e9e9;
  padding: 10px 20px;
  min-width: 8vw;
  text-align: center;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  color: #000000;
  font-weight: 600;
  cursor: pointer;
}

.userTabSelected {
  background: #f5f5fc;
}

.boleroTabCurve {
  background: #e9e9e9;
  width: 20px;
  -webkit-clip-path: polygon(0 calc(0% + 8px), 0% 100%, 100% 100%);
  clip-path: polygon(0 calc(0% + 8px), 0% 100%, 100% 100%);
  cursor: pointer;
  margin-left: -1px;
}

.commissionContent {
  height: 100vh;
  padding-top: 4%;
}
