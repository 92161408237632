.uploadPic{
    width: 215px;
    box-shadow: 0px 0px 5px #5fc8f8;
    border-radius: 5px;
   
    img{
        width: 100%;
        border-radius: 5px;
        cursor: pointer;
        &:disabled, &:disabled &:hover{
            cursor: not-allowed;
        }
    }
    button{
        width: 100%;
        border-radius: 5px;
        z-index: 2;
        margin-top: -2px;
        background: #e4e6e7;
        border: none;
        color: black;
        &:disabled, &:disabled &:hover{
            background: #044fff6e;
            cursor: not-allowed;
        }
    }
        }

        .edit_profile_form_wrap{
            display: flex;
            justify-content: space-between;
            margin-top: 32px;
            
        }
        form{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-basis: 58%;
            .MuiFormControl-root{
                margin-bottom: 10px!important;
            }
        }
        .edit_profile_container{
            border: 1px solid #d3cdcd9f;
            border-radius: 5px;
            background-color: white;
            padding: 30px 50px;
            font-family: Rubik, sans-serif;
        }
       .edit_icon_btn{
           position: absolute;
           right: 8px;
           width: 43px;
           height: 37px;
           top: 10px;
           cursor: pointer;
           color: white;
           padding: 0;
       }
       .edit_icon{
        width: 23px;
        height: 23px;
        margin: 3px 0 0 2px;
    
       }