.catagories {
    display: flex;
    width: 100%;
    box-shadow: 0px 0px 6px rgb(206, 205, 205);
    flex-wrap: wrap;
    margin-bottom: 50px;
    justify-content: space-between;
    border-right: 1px solid rgb(179, 179, 179);
}

.single-detail {
    padding: 25px;
    border: 1px solid rgb(179, 179, 179);
    flex-basis: 49.89%;
    min-width: 200px;
}

.single-detail:hover {
    background-color: rgba(201, 201, 201, 0.233);
    cursor: pointer;
}

.single-detail:nth-child(even) {
    border-right: none;
    border-left: none;
}

.single-detail p {
    font-size: 1.4em;
    color: rgb(70, 70, 70);
}

.chart-container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 40px;
    border-top: 1px solid #aaa;
}

.chart-container .single-chart {
    text-align: center;
    flex-basis: 49.89%;
}

.dateRangeContainer {
    width: 100%;
    margin: auto;
    margin-top: 20px;
    display: flex;
    align-items: center;
}
