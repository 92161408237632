.expandableBoxHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.expandableBoxHeader .section-one {
  flex: 0 0 40%;
}

.expandableBoxHeader .section-two {
  flex: 0 0 40%;
}

.expandableBoxHeader .section-three {
  flex: 0 0 20%;
}

.flexRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.paddingTop22 {
  padding-top: 22px;
}

.marginLeft14 {
  margin-left: 14px;
}

.cursorPointer {
  cursor: pointer;
}

/* To remove arrow from input=number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.expandableInputWidthHeight {
  max-height: 40px !important;
  max-width: 64% !important;
}

.tableExpandHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
}

.section-two {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
.section-three {
  display: flex;
  flex-direction: row;
  align-items: center;
}
