.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #77529f;
  width: 30px;
  height: 30px;
  position: relative;
  left: calc(50% - 15px);
  margin-bottom: 5px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

.overlayLoader {
  position: absolute;
  left: 0%;
  top: 0%;
  height: 100vh;
  width: 100vw;
  background: #000000a6;
  z-index: 1;
}

.overlayLoaderMini {
  position: absolute;
  left: 0%;
  top: 0%;
  height: 100%;
  width: 100%;
  background: #000000a6;
  z-index: 1;
}

.loaderContianer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 20000;
}

.customizeStyle {
  position: absolute !important;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.loaderContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.loaderInfo {
  font-weight: 600;
  color: #f3f3f3;
  text-align: center;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
