.paddingBottom66px {
  padding-bottom: 66px;
}

.CommissionBlueBox {
  background: rgb(232, 244, 253);
  width: 8rem;
  padding: 4px 9px;
  border: 1px solid #0f0f0f;
  border-radius: 6px;
  text-align: center;
}

.boxLabel {
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
}

.boxContent {
  font-weight: 500;
}
.flexingWrapper{
  display: flex;
  align-items: stretch;
  justify-content: center;
}
.total-value-p{
  font-weight: 500;
}
.total-value-p span{
  font-weight: 600;
  font-size: 12px;
}